@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');
body{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  background-color: #FFFFFF;
}

.header{
  position: relative;
  width: 100%;
  padding-left: 10%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0,0,0,.1);
}

.container{
 max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  margin-right: 0%;
  
}

.bigtitle{
  font-size:3.8rem;
  line-height: 4rem;
  font-family: CircularStd;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

ul li, p{
  font-size: 19px;
  font-weight: 100;
  line-height: 28px;
}

@media only screen and (max-width: 600px) {
  .col-1 {width: 100%;}
  .col-2 {width: 100%;}
  .col-3 {width: 100%;}
  .col-4 {width: 100%;}
  .col-5 {width: 100%;}
  .col-6 {width: 100%;}
  .col-7 {width: 100%;}
  .col-8 {width: 100%;}
  .col-9 {width: 100%;}
  .col-10 {width: 100%;}
  .col-11 {width: 100%;}
  .col-12 {width: 100%;}
  .bigtitle{
    font-size:2.5rem
  }
}
